import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "h-100 header-left" }
const _hoisted_2 = { class: "h-100 header-right" }
const _hoisted_3 = { class: "el-dropdown-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Expand = _resolveComponent("Expand")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_UserFilled = _resolveComponent("UserFilled")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_header = _resolveComponent("el-header")!

  return (_openBlock(), _createBlock(_component_el_header, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_avatar, {
          class: "app-bar-icon",
          onClick: _withModifiers(_ctx.toggleMenu, ["stop"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_Expand)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_router_link, {
          to: "/",
          class: "text-decoration-none app-link"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" eP2AS Licenses ")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.user != null)
          ? (_openBlock(), _createBlock(_component_el_dropdown, { key: 0 }, {
              dropdown: _withCtx(() => [
                _createVNode(_component_el_dropdown_menu, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_dropdown_item, null, {
                      default: _withCtx(() => [
                        _createTextVNode(" Email: " + _toDisplayString(_ctx.user.username), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_dropdown_item, { onClick: _ctx.logout }, {
                      default: _withCtx(() => [
                        _createTextVNode(" logout ")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_3, [
                  _createVNode(_component_el_avatar, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_UserFilled)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}