import { Auth } from 'aws-amplify';
import store from '@/store';

const LoginGuard = async (to: any, from: any, next: (to?: any) => void) => {
    if (store.state.auth.isLogged) {
        next();
    } else if (isAuthPath(to, next)) {
        next();
    } else {
        try {
            const user = await Auth.currentAuthenticatedUser();
            store.commit('auth/SET_LOGGED', user);
            if (store.state.auth.redirectPath) {
                next(store.state.auth.redirectPath);
            } else {
                next();
            }
        } catch (err) {
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath,
                },
            });
        }
    }
};

const isAuthPath = (to: any, next: (to?: any) => void) => {
    return to.matched.some((record: any) => record.meta.requiresUnAuth);
};
export { LoginGuard };
