import {
    FileUsage,
    QueryFileUsages,
    SearchFileUsages,
} from '@/types/FileUsages';
import {
    DEFAULT_LIMIT,
    DEFAULT_QUERY,
    PaginationData,
    QueryPaginate,
    SortEvent,
} from '@/types/PaginationData';
import { ObjectUtils } from '@/utils/ObjectUtils';
import { StringUtils } from '@/utils/StringUtils';
import { ActionTree, Module, MutationTree } from 'vuex';
import { fetchGetFileUsages } from '../fetch-api/fetch-usages';

export const fileUsagesModule: Module<any, any> = {
    namespaced: true,
    state: () => ({
        fileUsageArray: [] as FileUsage[],
        found: 0,
        lastPage: 0,
        currentPage: 1,
        pageSize: DEFAULT_LIMIT,
        isLoading: false,
        sortBy: [] as string[],
        sortDesc: [] as boolean[],
        query: DEFAULT_QUERY as QueryFileUsages,
    }),
    mutations: {
        FETCH_FILE_USAGES_SUCCESS(
            state,
            paginationData: PaginationData<FileUsage>,
        ) {
            state.fileUsageArray = paginationData.data;
            state.found = paginationData.found;
            state.lastPage = paginationData.lastPage;
            state.currentPage = paginationData.currentPage;
            state.pageSize = paginationData.pageSize;
            state.isLoading = false;
        },
        SET_LOADING(state, isLoading = true) {
            state.isLoading = isLoading;
        },
        SET_SEARCH_QUERY(state, searchParams: SearchFileUsages) {
            state.query = { limit: DEFAULT_LIMIT, offset: 0, ...searchParams };
        },
        SET_PAGINATE_QUERY(state, paginateParams: QueryPaginate) {
            state.query = { ...state.query, ...paginateParams };
        },
        SET_SORT_PARAMS(state, sortParams: SortEvent) {
            state.sortDesc = sortParams.sortDesc;
            state.sortBy = sortParams.sortBy;
        },
        SET_QUERY_PARAMS(state, params: QueryFileUsages) {
            state.query = { ...state.query, ...params };
        },
    } as MutationTree<any>,
    actions: {
        async fetchFileUsages(ctx) {
            let queryParams = '';
            try {
                ctx.commit('SET_LOADING', true);
                const payloadWithoutNulls = ObjectUtils.withoutNullKeyValues(
                    JSON.parse(JSON.stringify(ctx.state.query)),
                );
                queryParams = StringUtils.buildQueryParams(payloadWithoutNulls);
                const sortQuery = StringUtils.buildSortParams({
                    sortBy: ctx.state.sortBy,
                    sortDesc: ctx.state.sortDesc,
                });
                if (sortQuery.length > 0) {
                    queryParams = `${queryParams}&sort=${sortQuery}`;
                }
                const fileUsages: PaginationData<FileUsage> =
                    await fetchGetFileUsages(queryParams);
                ctx.commit('FETCH_FILE_USAGES_SUCCESS', fileUsages);
            } catch (e: any) {
                ctx.commit('errors/SET_ERROR', e);
                ctx.commit('SET_LOADING', false);
            }
        },
        async setSearch(ctx, searchParams: SearchFileUsages) {
            ctx.commit('SET_SEARCH_QUERY', searchParams);
            await ctx.dispatch('fetchFileUsages');
        },
        async setPaginate(ctx, queryParams: QueryPaginate) {
            ctx.commit('SET_PAGINATE_QUERY', queryParams);
            await ctx.dispatch('fetchFileUsages');
        },
        async fetchWithQueryParams(ctx, queryParams: QueryFileUsages) {
            ctx.commit('SET_QUERY_PARAMS', queryParams);
            await ctx.dispatch('fetchFileUsages');
        },
        async setSort(ctx, payload: SortEvent) {
            ctx.commit('SET_SORT_PARAMS', payload);
            await ctx.dispatch('fetchFileUsages');
        },
    } as ActionTree<any, any>,
    getters: {
        paginationData: (state) => {
            return {
                found: state.found,
                lastPage: state.lastPage,
                currentPage: state.currentPage,
                pageSize: state.pageSize,
                data: state.fileUsageArray,
            };
        },
        paginateQuery: (state) => {
            const offset = (state.currentPage - 1) * state.pageSize;
            const limit = state.currentPage * state.pageSize;
            return { offset, limit };
        },
    },
};
