
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'App',
    components: {},
    data: () => ({
        //
    }),
});
