import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_alert = _resolveComponent("el-alert")!

  return (_ctx.errorMessage)
    ? (_openBlock(), _createBlock(_component_el_alert, {
        key: 0,
        type: "error",
        class: "error-alert"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.errorMessage), 1),
          _createVNode(_component_el_icon, {
            class: "close-icon fas fa-times",
            onClick: _ctx.onClose
          }, null, 8, ["onClick"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}