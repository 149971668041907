
import { defineComponent } from 'vue';

export default defineComponent({
    components: {},
    props: {
        openMenu: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            drawer: false,
            linkLists: [
                {
                    label: 'Licenses',
                    items: [
                        {
                            path: 'licenses',
                            name: 'Licenses',
                            label: 'Licenses',
                        },
                    ],
                },
                {
                    label: 'Resource Usages',
                    items: [
                        {
                            path: 'api-usages',
                            name: 'ApiUsages',
                            label: 'Api usages',
                        },
                        {
                            path: 'file-usages',
                            name: 'FileUsages',
                            label: 'File usages',
                        },
                    ],
                },
            ],
        };
    },
    emits: ['toggle-menu'],
    watch: {
        openMenu(value) {
            this.drawer = value;
        },
        drawer(value) {
            this.$emit('toggle-menu', value);
        },
    },
});
