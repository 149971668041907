import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.isOpen,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isOpen) = $event)),
    title: _ctx.title,
    width: "30%",
    center: "",
    "show-close": false,
    "close-on-click-modal": false,
    "close-on-press-escape": false
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_el_button, {
          type: "danger",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSubmit(true)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Delete ")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSubmit(false)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Cancel ")
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("span", null, _toDisplayString(_ctx.text), 1)
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}