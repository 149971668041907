import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppConfirmDeleteDialog = _resolveComponent("AppConfirmDeleteDialog")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_AppSection = _resolveComponent("AppSection")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppConfirmDeleteDialog, {
      id: _ctx.idToDelete,
      title: "Delete License",
      text: `Are you sure you want to delete license with Organization Name = '${_ctx.itemName}'`,
      onSubmitDelete: _ctx.handleConfirmDelete
    }, null, 8, ["id", "text", "onSubmitDelete"]),
    (_ctx.$slots.hasOwnProperty('searchInputs'))
      ? (_openBlock(), _createBlock(_component_AppSection, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, { gutter: 10 }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "searchInputs"),
                (_ctx.showSearch)
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 0,
                      sm: 2,
                      class: "text-right"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "primary",
                          onClick: _ctx.handleSearch
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" SEARCH ")
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 3
            })
          ]),
          _: 3
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_AppSection, { class: "pa-0" }, {
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_el_table, {
          ref: "tableRef",
          data: _ctx.paginationData.data,
          "highlight-current-row": true,
          "automatic-dropdown": true,
          onSortChange: _ctx.handleSortChange
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (item) => {
              return (_openBlock(), _createBlock(_component_el_table_column, {
                key: item.value,
                prop: item.value,
                label: item.text,
                sortable: item.sortable ? 'custom' : false
              }, null, 8, ["prop", "label", "sortable"]))
            }), 128)),
            (_ctx.action)
              ? (_openBlock(), _createBlock(_component_el_table_column, {
                  key: 0,
                  label: "Actions",
                  align: "right",
                  width: "200"
                }, {
                  default: _withCtx((scope) => [
                    _createVNode(_component_el_button, {
                      class: "clickable",
                      size: "mini",
                      icon: _ctx.Edit,
                      onClick: ($event: any) => (_ctx.handleClickEdit(scope.$index))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Edit")
                      ]),
                      _: 2
                    }, 1032, ["icon", "onClick"]),
                    _createVNode(_component_el_button, {
                      class: "clickable",
                      size: "mini",
                      type: "danger",
                      icon: _ctx.Delete,
                      onClick: ($event: any) => (
                                _ctx.handleClickDelete(
                                    scope.row.id,
                                    scope.row.organizationName,
                                )
                            )
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Delete")
                      ]),
                      _: 2
                    }, 1032, ["icon", "onClick"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["data", "onSortChange"])), [
          [_directive_loading, _ctx.loading]
        ]),
        _createVNode(_component_el_pagination, {
          class: "margin-20-20",
          "current-page": _ctx.paginationData.currentPage,
          "pager-count": 5,
          "page-sizes": [1, 2, 5, 10, 20, 30],
          "page-size": _ctx.paginationData.pageSize,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _ctx.paginationData.found,
          onSizeChange: _ctx.handleSizeChange,
          onCurrentChange: _ctx.handleCurrentChange
        }, null, 8, ["current-page", "page-size", "total", "onSizeChange", "onCurrentChange"])
      ]),
      _: 1
    })
  ]))
}