import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_AppMenu = _resolveComponent("AppMenu")!
  const _component_ErrorAlert = _resolveComponent("ErrorAlert")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, {
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.drawer = false))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AppHeader, {
        onToggleMenu: _cache[0] || (_cache[0] = ($event: any) => (_ctx.drawer = !_ctx.drawer))
      }),
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_AppMenu, {
            "open-menu": _ctx.drawer,
            onToggleMenu: _ctx.toggleMenu
          }, null, 8, ["open-menu", "onToggleMenu"]),
          _createVNode(_component_el_main, { class: "app-main" }, {
            default: _withCtx(() => [
              _createVNode(_component_ErrorAlert),
              _createVNode(_component_router_view)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}