
import CreateEditLicenseForm from '@/components/licenses/CreateEditLicenseForm.vue';
import { License } from '@/types/License';
import { defineComponent, ref, reactive, computed, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import AppBreadcrumb from '@/components/AppBreadcrumb.vue';
import AppContainer from '@/components/AppContainer.vue';
import { useStore } from 'vuex';

const EditeLicense = defineComponent({
    components: {
        AppContainer,
        AppBreadcrumb,
        CreateEditLicenseForm,
    },
    props: {
        licenseId: {
            type: [Number, String],
            required: true,
        },
    },
    setup(props) {
        const store = useStore();
        const router = useRouter();
        const data = reactive({
            breadcrumbItems: [
                {
                    text: 'Index',
                    to: '/',
                    exact: true,
                },
                {
                    text: 'Licenses',
                    to: '/licenses',
                    exact: true,
                },
                {
                    text: 'Edit',
                },
            ],
            paginationData: computed(() => {
                return store.getters['licenses/paginationData'];
            }) as any,
        });
        const licenseInfo = ref<License>(
            data.paginationData.data[Number(props.licenseId)],
        );
        console.log(props.licenseId);
        console.log(data.paginationData);
        const submit = async (fields: License) => {
            store
                .dispatch('licenses/updateLicense', fields)
                .then(async (result) => {
                    if (result.success) {
                        await store.dispatch('licenses/fetchLicenses');
                        router.push({ name: 'ShowLicenses' });
                    } else {
                        store.commit('errors/SET_ERROR', result.error);
                    }
                });
        };

        return {
            ...toRefs(data),
            licenseInfo,
            submit,
        };
    },
});
export default EditeLicense;
