
import SearchArea from '@/components/SearchArea.vue';
import {
    DEFAULT_QUERY,
    QueryPaginate,
    SortEvent,
} from '@/types/PaginationData';
import { defineComponent, reactive, computed, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AppBreadcrumb from '@/components/AppBreadcrumb.vue';
import AppContainer from '@/components/AppContainer.vue';
import { useStore } from 'vuex';
import { SearchFileUsages } from '@/types/FileUsages';

export default defineComponent({
    components: { AppContainer, AppBreadcrumb, SearchArea },
    setup() {
        const router = useRouter();
        const store = useStore();
        const data = reactive({
            breadcrumbItems: [
                {
                    text: 'Index',
                    to: '/',
                    exact: true,
                },
                { text: 'Api usages' },
            ],
            headers: [
                {
                    text: 'License ID',
                    value: 'licenseId',
                    selected: true,
                    sortable: true,
                },
                {
                    text: 'License key',
                    value: 'licenseKey',
                    selected: true,
                    sortable: false,
                },
                {
                    text: 'Organization Name',
                    value: 'organizationName',
                    selected: true,
                    sortable: true,
                },
                {
                    text: 'File Type',
                    value: 'fileType',
                    selected: true,
                    sortable: true,
                },
                {
                    text: 'Total File Size',
                    value: 'totalFilesSize',
                    selected: true,
                    sortable: true,
                },
                {
                    text: 'Date',
                    value: 'date',
                    sortable: true,
                    selected: true,
                },
            ],
            searchQuery: {
                date: '',
                organizationName: '',
                licenseKey: '',
                fileType: undefined,
            } as SearchFileUsages,
            paginationData: computed(() => {
                return store.getters['fileUsages/paginationData'];
            }),
            isLoading: computed(() => {
                return store.state.fileUsages.isLoading;
            }),
            query: computed(() => {
                return store.state.fileUsages.query;
            }),
        });

        const handleClickSearch = () => {
            store.dispatch('fileUsages/setSearch', data.searchQuery);
            router.replace({ query: store.state.fileUsages.query as any });
        };

        const handleClickSort = (event: SortEvent) => {
            store.dispatch('fileUsages/setSort', event);
        };

        const handleClickSizeChange = (limit: number) => {
            const event: QueryPaginate = {
                limit: limit,
                offset: 0,
            };
            store.dispatch('fileUsages/setPaginate', event);
            router.replace({ query: store.state.fileUsages.query as any });
        };

        const handleClickCurrentChange = (event: QueryPaginate) => {
            store.dispatch('fileUsages/setPaginate', event);
            router.replace({
                query: store.state.fileUsages.query as any,
            });
        };

        return {
            ...toRefs(data),
            handleClickSearch,
            handleClickSort,
            handleClickSizeChange,
            handleClickCurrentChange,
        };
    },
    created() {
        const router = useRouter();
        const store = useStore();
        store.dispatch('fileUsages/setSearch');
        router.replace({ query: store.state.fileUsages.query as any });
    },
});
