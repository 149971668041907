
import { defineComponent, PropType } from 'vue';
const AppBreadcrumb = defineComponent({
    props: {
        items: {
            type: Object as PropType<any[]>,
        },
    },
});
export default AppBreadcrumb;
