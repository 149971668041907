
import ErrorAlert from '@/components/ErrorAlert.vue';
import { defineComponent } from 'vue';
import AppContainer from '@/components/AppContainer.vue';

const Auth = defineComponent({
    props: {
        prop: {
            type: String,
        },
    },
    components: { AppContainer, ErrorAlert },
});
export default Auth;
