import CreateLicense from '@/views/licenses/Create.vue';
import EditeLicense from '@/views/licenses/Edit.vue';
import ShowLicenses from '@/views/licenses/Index.vue';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/licenses',
        name: 'ShowLicenses',
        component: ShowLicenses,
    },
    {
        path: '/licenses/create',
        name: 'CreateLicense',
        component: CreateLicense,
    },
    {
        path: '/licenses/:licenseId/edit',
        name: 'EditeLicense',
        component: EditeLicense,
        props: true,
    },
];

export default routes;
