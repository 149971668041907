export class ObjectUtils {
    // remove keys of object that contain null values
    static withoutNullKeyValues = (obj: any, exclude?: string[]) => {
        for (const propName in obj) {
            if (exclude && exclude.includes(propName)) {
                continue;
            }
            if (obj[propName] === "" || obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
        return obj;
    };
}
