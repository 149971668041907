
import SearchArea from '@/components/SearchArea.vue';
import { SearchLicenses } from '@/types/License';
import {
    DEFAULT_QUERY,
    QueryPaginate,
    SortEvent,
} from '@/types/PaginationData';
import { defineComponent, reactive, computed, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AppBreadcrumb from '@/components/AppBreadcrumb.vue';
import AppContainer from '@/components/AppContainer.vue';
import { useStore } from 'vuex';

const ShowLicenses = defineComponent({
    components: { AppContainer, AppBreadcrumb, SearchArea },
    setup() {
        const router = useRouter();
        const store = useStore();
        const data = reactive({
            breadcrumbItems: [
                {
                    text: 'Index',
                    to: '/',
                    exact: true,
                },
                { text: 'Licenses' },
            ],
            headers: [
                {
                    text: 'License ID',
                    value: 'id',
                    selected: true,
                    sortable: true,
                },
                {
                    text: 'License key',
                    value: 'licenseKey',
                    selected: true,
                    sortable: false,
                },
                {
                    text: 'Organization Name',
                    value: 'organizationName',
                    selected: true,
                    sortable: true,
                },
                {
                    text: 'Lease start date',
                    value: 'leaseStartDate',
                    selected: true,
                    sortable: true,
                },
                {
                    text: 'Expired date',
                    value: 'expiredDate',
                    selected: true,
                    sortable: true,
                },
                {
                    text: 'Is active',
                    value: 'isLicensed',
                    selected: true,
                    sortable: true,
                },
            ],
            searchQuery: {
                organizationName: null,
                isLicensed: undefined,
                licenseKey: null,
            } as SearchLicenses,
            paginationData: computed(() => {
                return store.getters['licenses/paginationData'];
            }),
            isLoading: computed(() => {
                return store.state.licenses.isLoading;
            }),
            query: computed(() => {
                return store.state.licenses.query;
            }),
        });

        const handleClickCreate = () => {
            router.push({
                name: 'CreateLicense',
            });
        };

        const handleClickEdit = (index: number) => {
            router.push({
                name: 'EditeLicense',
                params: { licenseId: index },
            });
        };

        const handleClickSearch = () => {
            store.dispatch('licenses/setSearch', data.searchQuery);
            router.replace({ query: store.state.licenses.query as any });
        };

        const handleClickSort = (event: SortEvent) => {
            store.dispatch('licenses/setSort', event);
        };

        const handleClickSizeChange = (limit: number) => {
            const event: QueryPaginate = {
                limit: limit,
                offset: 0,
            };
            store.dispatch('licenses/setPaginate', event);
            router.replace({ query: store.state.licenses.query as any });
        };

        const handleClickCurrentChange = (event: QueryPaginate) => {
            store.dispatch('licenses/setPaginate', event);
            router.replace({
                query: store.state.licenses.query as any,
            });
        };

        const handleClickDelete = async (id: number) => {
            console.log(id);
            store.dispatch('licenses/deleteLicense', id).then((result) => {
                if (result.success) {
                    store.dispatch('licenses/fetchLicenses');
                } else {
                    store.commit('errors/SET_ERROR', result.error);
                }
            });
        };

        const handleClearLicensed = () => {
            data.searchQuery.isLicensed = undefined;
        };

        return {
            ...toRefs(data),
            handleClickCreate,
            handleClickEdit,
            handleClickSearch,
            handleClickSort,
            handleClickSizeChange,
            handleClickCurrentChange,
            handleClickDelete,
            handleClearLicensed,
        };
    },
    created() {
        const router = useRouter();
        const store = useStore();
        store.dispatch('licenses/setSearch');
        router.replace({ query: store.state.licenses.query as any });
    },
});
export default ShowLicenses;
