export const DEFAULT_LIMIT = 10;
export const DEFAULT_OFFSET = 0;

export const DEFAULT_QUERY = { limit: DEFAULT_LIMIT, offset: DEFAULT_OFFSET };

export enum ResultType {
    success = 'Success',
    failure = 'Failure',
}
export interface PaginationData<T> {
    result?: ResultType;
    found: number;
    lastPage: number;
    currentPage: number;
    pageSize: number;
    data: Array<T>;
}
export interface QueryPaginate {
    limit: number;
    offset: number;
}

/*export interface PaginationEventResult {
  page: number;
  itemsPerPage: number;
  pageStart: number;
  pageStop: number;
  pageCount: number;
  itemsLength: number;
}*/

export interface PaginationEventResult {
    groupBy: any[];
    groupDesc: any[];
    itemsPerPage: number;
    multiSort: boolean;
    mustSort: boolean;
    page: number;
    sortBy: any[];
    sortDesc: any[];
}

export interface SortEvent {
    sortBy: string[];
    sortDesc: boolean[];
}
