import { License } from '@/types/License';
import { fetchApi, HttpMethod } from './fetch-api';

export const fetchGetLicenses = async (queryParams: string) => {
    return await fetchApi(`licenses?${queryParams}`, HttpMethod.GET);
};
export const fetchPostLicense = async (fields: Partial<License>) => {
    return await fetchApi(`licenses`, HttpMethod.POST, fields);
};
export const fetchPutLicense = async (fields: Partial<License>, id: number) => {
    return await fetchApi(`licenses/${id}`, HttpMethod.PUT, fields);
};

export const fetchDeleteLicense = async (id: number) => {
    return await fetchApi(`licenses/${id}`, HttpMethod.DELETE, {
        isActive: false,
    });
};
