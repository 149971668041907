
import { defineComponent, ref, PropType, reactive, toRefs } from 'vue';
import { License } from '../../types/License';
import AppSection from '@/components/AppSection.vue';

const CreateEditLicenseForm = defineComponent({
    components: { AppSection },
    props: {
        license: {
            type: Object as PropType<License>,
            default: () => {
                return {
                    organizationName: null,
                    maxNumberOfAccessedApisPerOrganization: 0,
                    maxFilesSizePerOrganization: 0,
                    maxNumberOfEp2asVueUsers: 0,
                    maxNumberOfSubmissionUnits: 0,
                    allowedEctdVersions: ['v3.2.2', "v4.0"],
                    allowedRemoteRepositories: [
                        "wfs_smb_v2_v3",
                        "sharepoint",
                        "sharepoint_entraid",
                    ],
                    leaseStartDate: '',
                    expiredDate: '',
                    isLicensed: true,
                    isAllowedWithoutLicenseKey: false,
                };
            },
        },
    },
    emits: ['submit'],
    setup(props, context) {
        console.log(props.license);
        const formData = reactive({
            form: { ...props.license } as any,
            formRef: ref(),
            rules: {
                organizationName: [
                    {
                        required: true,
                        message: 'Please input Organization name',
                        trigger: 'blur',
                    },
                ],
                maxNumberOfAccessedApisPerOrganization: [
                    {
                        type: 'number',
                        required: true,
                        message: 'Please input the number',
                        trigger: 'blur',
                    },
                    {
                        validator: (rule: any, value: any) => {
                            if (
                                Number(value) >= -1 &&
                                Number(value) <= 2147483647
                            ) {
                                return true;
                            } else {
                                return false;
                            }
                        },
                        message: 'The value must be -1 or higher.',
                        trigger: ['blur', 'change'],
                    },
                    {
                        pattern: /^(0|-*[1-9][0-9]*)$/,
                        message: 'The value must be integer',
                        trigger: ['blur', 'change'],
                    },                ],
                maxFilesSizePerOrganization: [
                    {
                        type: 'number',
                        required: true,
                        message: 'Please input the number',
                        trigger: 'blur',
                    },
                    {
                        validator: (rule: any, value: any) => {
                            if (
                                Number(value) >= -1 &&
                                Number(value) <= 2147483647
                            ) {
                                return true;
                            } else {
                                return false;
                            }
                        },
                        message: 'The value must be -1 or higher.',
                        trigger: ['blur', 'change'],
                    },
                    {
                        pattern: /^(0|-*[1-9][0-9]*)$/,
                        message: 'The value must be integer',
                        trigger: ['blur', 'change'],
                    },
                ],
                maxNumberOfEp2asVueUsers: [
                    {
                        type: 'number',
                        required: true,
                        message: 'Please input the number',
                        trigger: 'blur',
                    },
                    {
                        validator: (rule: any, value: any) => {
                            if (
                                Number(value) >= -1 &&
                                Number(value) <= 2147483647
                            ) {
                                return true;
                            } else {
                                return false;
                            }
                        },
                        message: 'The value must be -1 or higher.',
                        trigger: ['blur', 'change'],
                    },
                    {
                        pattern: /^(0|-*[1-9][0-9]*)$/,
                        message: 'The value must be integer',
                        trigger: ['blur', 'change'],
                    },
                ],
                maxNumberOfSubmissionUnits: [
                    {
                        type: 'number',
                        required: true,
                        message: 'Please input the number',
                        trigger: 'blur',
                    },
                    {
                        validator: (rule: any, value: any) => {
                            if (
                                Number(value) >= -1 &&
                                Number(value) <= 2147483647
                            ) {
                                return true;
                            } else {
                                return false;
                            }
                        },
                        message: 'The value must be -1 or higher.',
                        trigger: ['blur', 'change'],
                    },
                    {
                        pattern: /^(0|-*[1-9][0-9]*)$/,
                        message: 'The value must be integer',
                        trigger: ['blur', 'change'],
                    },
                ],
                leaseStartDate: [
                    {
                        type: 'date',
                        required: true,
                        message: 'Please select the lease start date',
                        trigger: 'change',
                    },
                    {
                        validator: (rule: any, value: any) => {
                            if (!value || !formData.form.expiredDate) return;
                            const d1 = new Date(value);
                            const d2 = new Date(formData.form.expiredDate);
                            return d1 < d2;
                        },
                        message:
                            'The lease start date must be before the expired date',
                        trigger: 'change',
                    },
                ],
                expiredDate: [
                    {
                        type: 'date',
                        required: true,
                        message: 'Please select the expired date',
                        trigger: 'change',
                    },
                    {
                        validator: (rule: any, value: any) => {
                            if (!value || !formData.form.leaseStartDate) return;
                            const d1 = new Date(value);
                            const d2 = new Date(formData.form.leaseStartDate);
                            return d1 > d2;
                        },
                        message:
                            'The expired date must be after the lease start date',
                        trigger: 'change',
                    },
                ],
            } as any,
        });

        const loading = ref(false)

        const submit = () => {
            formData.formRef.validate((valid: boolean) => {
                if (valid) {
                    loading.value=true
                    context.emit('submit', formData.form);
                } else {
                    return false;
                }
            });
        };

        return {
            ...toRefs(formData),
            submit,
            loading
        };
    },
});
export default CreateEditLicenseForm;
