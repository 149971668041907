
import AppConfirmDeleteDialog from '@/components/AppConfirmDeleteDialog.vue';
import { License } from '@/types/License';
import {
    DEFAULT_LIMIT,
    PaginationData,
    QueryPaginate,
    SortEvent,
} from '@/types/PaginationData';
import { defineComponent, PropType, reactive, toRefs } from 'vue';
import AppSection from '@/components/AppSection.vue';
import { Edit, Delete } from '@element-plus/icons';

export interface DataTableHeader {
    text: string;
    sortable?: boolean;
    value: string;
    selected: boolean;
}

const SearchArea = defineComponent({
    components: {
        AppSection,
        AppConfirmDeleteDialog,
    },
    props: {
        headers: {
            type: Array as PropType<DataTableHeader[]>,
            required: true,
        },
        query: {
            type: Object,
            required: true,
        },
        paginationData: {
            type: Object as PropType<PaginationData<License>>,
            required: true,
        },
        loading: {
            type: Boolean,
        },
        action: {
            type: Boolean,
            default: () => true,
        },
        showSearch: {
            type: Boolean,
            default: () => false,
        },
    },
    emits: [
        'edit',
        'delete',
        'search',
        'size-change',
        'current-change',
        'sort',
    ],
    setup(props, context) {
        const data = reactive({
            idToDelete: null as number | null,
            itemName: '',
        });

        const handleClickEdit = (index: number) => {
            context.emit('edit', index);
        };

        const handleClickDelete = (id: number, name: string) => {
            data.idToDelete = id;
            data.itemName = name;
        };

        const handleConfirmDelete = (isAgree: boolean) => {
            console.log(isAgree);
            if (isAgree) {
                context.emit('delete', data.idToDelete);
            }
            data.idToDelete = null;
        };

        const handleSearch = () => {
            context.emit('search');
        };

        const handleSizeChange = (val: number) => {
            context.emit('size-change', val);
        };

        const handleCurrentChange = (val: number) => {
            const limit =
                props.paginationData.pageSize > 0
                    ? props.paginationData.pageSize
                    : DEFAULT_LIMIT;
            const event = {
                limit: limit,
                offset: (val - 1) * props.paginationData.pageSize,
            } as QueryPaginate;
            context.emit('current-change', event);
        };

        const handleSortChange = (event: any) => {
            const sort = {
                sortBy: [event.prop ? event.prop : ''],
                sortDesc: [
                    !event.order
                        ? ''
                        : event.order == 'ascending'
                        ? false
                        : true,
                ],
            } as SortEvent;
            context.emit('sort', sort);
        };

        return {
            ...toRefs(data),
            handleClickEdit,
            handleClickDelete,
            handleConfirmDelete,
            handleSearch,
            handleSizeChange,
            handleCurrentChange,
            handleSortChange,
            Edit,
            Delete,
        };
    },
});
export default SearchArea;
