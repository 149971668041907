
import AppHeader from '@/components/AppHeader.vue';
import AppMenu from '@/components/AppMenu.vue';
import ErrorAlert from '@/components/ErrorAlert.vue';
import { defineComponent, reactive, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const Entered = defineComponent({
    components: { ErrorAlert, AppHeader, AppMenu },
    data() {
        return {
            drawer: false,
        };
    },
    methods: {
        toggleMenu(toggle: boolean) {
            this.drawer = toggle;
        },
    },
    setup() {
        const route = useRoute();
        const store = useStore();

        watch(
            () => route.meta,
            () => {
                store.commit('errors/RESET_ERROR_MESSAGE');
            },
            { immediate: true, deep: true },
        );

        return {};
    },
});
export default Entered;
