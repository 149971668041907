
import AppErrorField from '@/components/AppErrorField.vue';
import { CognitoError } from '@/types/Cognito';
import { Auth } from 'aws-amplify';
import { defineComponent, ref, reactive, toRefs, computed } from 'vue';
import { useRouter } from 'vue-router';
import { Lock } from '@element-plus/icons';
import { useStore } from 'vuex';
  import {
    CognitoUser,
  } from 'amazon-cognito-identity-js'

const NewPassword = defineComponent({
    components: { AppErrorField },
    setup() {
        const router = useRouter();
        const store = useStore();
        const formData = reactive({
            form: { newPassword: '' },
            formRef: ref(),
            errorMsg: ref<string | undefined>(''),
            rules: {
                password: [
                    {
                        required: true,
                        message: 'Please input the password',
                        trigger: ['blur'],
                    },
                ],
            } as any,
        });
        const cognitoUser = computed((): CognitoUser => {
            return store.state.auth.user;
        });
        const changePassword = (user: CognitoUser) => {
            console.log('user', user)
            Auth.completeNewPassword(user, formData.form.newPassword, {})
                .then((completedUser) => {
                    // at this time the user is logged in if no MFA
                    store.commit('auth/SET_LOGGED', completedUser);
                    router.push('/');
                })
                .catch((e: CognitoError) => {
                    formData.errorMsg = e.message;
                });
        };

        return {
            ...toRefs(formData),
            cognitoUser,
            changePassword,
            Lock,
        };
    },
});
export default NewPassword;
