import { fetchApi, HttpMethod } from './fetch-api';

export const fetchGetApiUsages = async (queryParams: string) => {
    return await fetchApi(
        `api-usage/requests/counts?${queryParams}`,
        HttpMethod.GET,
    );
};

export const fetchGetFileUsages = async (queryParams: string) => {
    return await fetchApi(
        `api-usage/files/counts?${queryParams}`,
        HttpMethod.GET,
    );
};
