import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!

  return (_openBlock(), _createBlock(_component_el_breadcrumb, {
    separator: "/",
    class: "app-breadcrumb"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, idx) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: idx }, [
          (item.to)
            ? (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
                key: 0,
                to: { path: item.to }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.text), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            : (_openBlock(), _createBlock(_component_el_breadcrumb_item, { key: 1 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.text), 1)
                ]),
                _: 2
              }, 1024))
        ], 64))
      }), 128))
    ]),
    _: 1
  }))
}