
import CreateEditLicenseForm from '@/components/licenses/CreateEditLicenseForm.vue';
import { License } from '@/types/License';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import AppBreadcrumb from '@/components/AppBreadcrumb.vue';
import AppContainer from '@/components/AppContainer.vue';
import { useStore } from 'vuex';

const CreateLicense = defineComponent({
    components: {
        AppContainer,
        AppBreadcrumb,
        CreateEditLicenseForm,
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const breadcrumbItems = ref([
            {
                text: 'Index',
                to: '/',
                exact: true,
            },
            {
                text: 'Licenses',
                to: '/licenses',
                exact: true,
            },
            {
                text: 'Create',
            },
        ]);
        const submit = async (fields: License) => {
            store
                .dispatch('licenses/createLicense', fields)
                .then(async (result) => {
                    if (result.success) {
                        await store.dispatch('licenses/fetchLicenses');
                        router.push({ name: 'ShowLicenses' });
                    } else {
                        store.commit('errors/SET_ERROR', result.error);
                    }
                });
        };

        return {
            breadcrumbItems,
            submit,
        };
    },
});
export default CreateLicense;
