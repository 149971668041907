import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu_item_group = _resolveComponent("el-menu-item-group")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createBlock(_component_el_drawer, {
    modelValue: _ctx.drawer,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.drawer) = $event)),
    title: "eP2AS Licenses menu",
    direction: "ltr",
    size: 260
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_menu, {
        class: "w-100",
        router: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.linkLists, (links) => {
            return (_openBlock(), _createBlock(_component_el_menu_item_group, {
              key: links.label,
              title: links.label
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(links.items, (link) => {
                  return (_openBlock(), _createBlock(_component_el_menu_item, {
                    key: link.name,
                    route: `/${link.path}`,
                    index: link.name,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.drawer = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(link.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["route", "index"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["title"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}