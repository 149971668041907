
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

const ErrorAlert = defineComponent({
    setup() {
        const store = useStore();
        const errorMessage = computed(() => {
            return store.state.errors.errorMessage;
        });

        const onClose = () => {
            return store.commit('errors/RESET_ERROR_MESSAGE');
        };
        return {
            errorMessage,
            onClose,
        };
    },
});
export default ErrorAlert;
