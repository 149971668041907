import { createStore } from 'vuex';
import { authModule } from './modules/auth';
import { errorsModule } from './modules/errors';
import { licenseModule } from './modules/licenses';
import { apiUsagesModule } from './modules/api-usages';
import { fileUsagesModule } from './modules/file-usages';

export default createStore({
    strict: true,
    modules: {
        auth: authModule,
        errors: errorsModule,
        licenses: licenseModule,
        apiUsages: apiUsagesModule,
        fileUsages: fileUsagesModule,
    },
});
