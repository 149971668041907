import { SortEvent } from '@/types/PaginationData';

export class StringUtils {
    // sample: {foo: "foo", bar: "bar"}  => foo=foo&bar=bar
    static buildQueryParams = (params: any) => {
        return Object.keys(params)
            .map(function (k) {
                return (
                    encodeURIComponent(k) + '=' + encodeURIComponent(params[k])
                );
            })
            .join('&');
    };
    static buildSortParams = (params: SortEvent) => {
        return params.sortBy
            .map((value, index) => {
                if (params.sortDesc[index]) {
                    return `-${value}`;
                }
                return value;
            })
            .join(',');
    };
}
