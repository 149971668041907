
import { defineComponent } from 'vue';
const AppErrorField = defineComponent({
    props: {
        error: {
            type: String,
            default: '',
        },
    },
});
export default AppErrorField;
