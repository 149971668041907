
import AppErrorField from '@/components/AppErrorField.vue';
import { Auth } from 'aws-amplify';
import { defineComponent, reactive, ref, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { Message } from '@element-plus/icons';

interface ForgotPasswordStruct {
    email: string;
}

const ForgotPassword = defineComponent({
    components: { AppErrorField },
    setup() {
        const router = useRouter();
        const formData = reactive({
            form: { email: '' } as ForgotPasswordStruct,
            formRef: ref(),
            errorMsg: ref<string | null>(null),
            rules: {
                email: [
                    {
                        required: true,
                        message: 'Please input email address',
                        trigger: ['blur'],
                    },
                    {
                        type: 'email',
                        message: 'Please input correct email address',
                        trigger: ['blur', 'change'],
                    },
                ],
            } as any,
        });

        const forgotPassword = () => {
            formData.formRef.validate((valid: boolean) => {
                if (valid) {
                    Auth.forgotPassword(formData.form.email)
                        .then(() => {
                            router.push({
                                name: 'ForgotPasswordConfirmation',
                                params: { username: formData.form.email },
                            });
                        })
                        .catch((err) => {
                            formData.errorMsg = err.message;
                        });
                } else {
                    return false;
                }
            });
        };

        return {
            ...toRefs(formData),
            forgotPassword,
            Message,
        };
    },
});
export default ForgotPassword;
