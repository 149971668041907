
import AppErrorField from '@/components/AppErrorField.vue';
import { Auth } from 'aws-amplify';
import { defineComponent, ref, reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { Message, Lock } from '@element-plus/icons';

interface ConfirmationStruct {
    code: string;
    email: string;
    newPassword: string;
}

const ForgetPasswordConfirmation = defineComponent({
    components: { AppErrorField },
    props: {
        username: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const router = useRouter();
        const formData = reactive({
            form: {
                code: '',
                email: props.username,
                newPassword: '',
            } as ConfirmationStruct,
            formRef: ref(),
            errorMsg: ref<string | null>(null),
            rules: {
                code: [
                    {
                        required: true,
                        message: 'Please input the code',
                        trigger: ['blur'],
                    },
                ],
                email: [
                    {
                        required: true,
                        message: 'Please input email address',
                        trigger: ['blur'],
                    },
                    {
                        type: 'email',
                        message: 'Please input correct email address',
                        trigger: ['blur', 'change'],
                    },
                ],
                password: [
                    {
                        required: true,
                        message: 'Please input the password',
                        trigger: ['blur'],
                    },
                ],
            } as any,
        });
        const changePassword = () => {
            formData.formRef.validate(async (valid: boolean) => {
                if (valid) {
                    Auth.forgotPasswordSubmit(
                        formData.form.email!,
                        formData.form.code,
                        formData.form.newPassword,
                    )
                        .then(() => {
                            router.push('/');
                        })
                        .catch((err) => {
                            formData.errorMsg = err.message;
                        });
                } else {
                    return false;
                }
            });
        };

        return {
            ...toRefs(formData),
            changePassword,
            Message,
            Lock,
        };
    },
});
export default ForgetPasswordConfirmation;
