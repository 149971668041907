import config from '@/config';
import '@fortawesome/fontawesome-free/css/all.css';
import  {Amplify, Auth } from 'aws-amplify';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { ApiName } from './store/fetch-api/fetch-api';
import './assets/globals.css';
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';

Amplify.configure({
    Auth: {
        // identityPoolId: config.IdentityPoolId, // REQUIRED - Amazon Cognito Identity Pool ID
        region: config.Region, // REQUIRED - Amazon Cognito Region
        userPoolId: config.UserPoolId, // OPTIONAL - Amazon Cognito User Pool ID
        userPoolWebClientId: config.ClientId, // sOPTIONAL - Amazon Cognito Web Client ID

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
    API: {
        endpoints: [
            {
                name: ApiName,
                endpoint: config.ApiEndpoint,
                // prettier-ignore
                'custom_header': async () => {
                    return {
                        Authorization: `Bearer ${(await Auth.currentSession())
                          .getIdToken()
                          .getJwtToken()}`,
                    };
                },
            },
        ],
    },
});

const app = createApp(App)
    .use(router)
    .use(store)
    .use(ElementPlus)
    .mount('#app');
