import {
    fetchDeleteLicense,
    fetchGetLicenses,
    fetchPostLicense,
    fetchPutLicense,
} from '@/store/fetch-api/fetch-licenses';
import {
    DEFAULT_LIMIT,
    DEFAULT_QUERY,
    PaginationData,
    QueryPaginate,
    SortEvent,
} from '@/types/PaginationData';
import { ObjectUtils } from '@/utils/ObjectUtils';
import { StringUtils } from '@/utils/StringUtils';
import { License, QueryLicenses, SearchLicenses } from '@/types/License';
import { ActionTree, Module, MutationTree } from 'vuex';

export const licenseModule: Module<any, any> = {
    namespaced: true,
    state: () => ({
        licensesArray: [] as License[],
        found: 0,
        lastPage: 0,
        currentPage: 1,
        pageSize: DEFAULT_LIMIT,
        isLoading: false,
        sortBy: [] as string[],
        sortDesc: [] as boolean[],
        query: DEFAULT_QUERY as QueryLicenses,
    }),
    mutations: {
        FETCH_LICENSES_SUCCESS(state, paginationData: PaginationData<License>) {
            state.licensesArray = paginationData.data;
            state.found = paginationData.found;
            state.lastPage = paginationData.lastPage;
            state.currentPage = paginationData.currentPage;
            state.pageSize = paginationData.pageSize;
            state.isLoading = false;
        },
        SET_LOADING(state, isLoading = true) {
            state.isLoading = isLoading;
        },
        CREATE_LICENSE_SUCCESS(state, license: License) {
            state.found++;
            state.currentPage =
                Math.ceil(state.query.offset / state.query.limit) + 1;
            state.lastPage = Math.max(
                1,
                Math.ceil(state.found / state.query.limit),
            );
            state.pageSize = state.query.limit;
            if (state.licensesArray.length < state.pageSize) {
                state.licensesArray.push(license);
            }
        },
        UPDATE_LICENSE_SUCCESS(state, resUpdated: License) {
            state.licensesArray = state.licensesArray.map((res: any) => {
                return res.id === resUpdated.id ? resUpdated : res;
            });
        },
        DELETE_LICENSE_SUCCESS(state, id: number) {
            state.licensesArray = state.licensesArray.filter((res: any) => {
                return res.id != id;
            });
            state.found = state.found - 1;
            state.currentPage =
                Math.ceil(state.query.offset / state.query.limit) + 1;
            state.lastPage = Math.max(
                1,
                Math.ceil(state.found / state.query.limit),
            );
            state.pageSize = state.query.limit;
        },
        SET_SEARCH_QUERY(state, searchParams: SearchLicenses) {
            state.query = { limit: DEFAULT_LIMIT, offset: 0, ...searchParams };
        },
        SET_PAGINATE_QUERY(state, paginateParams: QueryPaginate) {
            state.query = { ...state.query, ...paginateParams };
        },
        SET_SORT_PARAMS(state, sortParams: SortEvent) {
            state.sortDesc = sortParams.sortDesc;
            state.sortBy = sortParams.sortBy;
        },
        SET_QUERY_PARAMS(state, params: QueryLicenses) {
            state.query = { ...state.query, ...params };
        },
    } as MutationTree<any>,
    actions: {
        async fetchLicenses(ctx) {
            let queryParams = '';
            try {
                ctx.commit('SET_LOADING', true);
                const payloadWithoutNulls = ObjectUtils.withoutNullKeyValues(
                    JSON.parse(JSON.stringify(ctx.state.query)),
                );
                queryParams = StringUtils.buildQueryParams(payloadWithoutNulls);
                const sortQuery = StringUtils.buildSortParams({
                    sortBy: ctx.state.sortBy,
                    sortDesc: ctx.state.sortDesc,
                });
                if (sortQuery.length > 0) {
                    queryParams = `${queryParams}&sort=${sortQuery}`;
                }
                const licenses: PaginationData<License> =
                    await fetchGetLicenses(queryParams);
                ctx.commit('FETCH_LICENSES_SUCCESS', licenses);
            } catch (e: any) {
                ctx.commit('errors/SET_ERROR', e);
                ctx.commit('SET_LOADING', false);
            }
        },
        async setSearch(ctx, searchParams: SearchLicenses) {
            ctx.commit('SET_SEARCH_QUERY', searchParams);
            await ctx.dispatch('fetchLicenses');
        },
        async setPaginate(ctx, queryParams: QueryPaginate) {
            ctx.commit('SET_PAGINATE_QUERY', queryParams);
            await ctx.dispatch('fetchLicenses');
        },
        async fetchWithQueryParams(ctx, queryParams: QueryLicenses) {
            ctx.commit('SET_QUERY_PARAMS', queryParams);
            await ctx.dispatch('fetchLicenses');
        },
        async createLicense(ctx, payload: License) {
            try {
                const response = await fetchPostLicense(payload);
                return {
                    success: true,
                    data: response.data,
                };
            } catch (e) {
                return {
                    success: false,
                    error: e,
                };
            }
        },
        async updateLicense(ctx, payload: License) {
            try {
                const { id, ...others } = payload;
                const response = await fetchPutLicense(others, id!);
                return {
                    success: true,
                    data: response.data,
                };
            } catch (e) {
                return {
                    success: false,
                    error: e,
                };
            }
        },
        async setSort(ctx, payload: SortEvent) {
            ctx.commit('SET_SORT_PARAMS', payload);
            await ctx.dispatch('fetchLicenses');
        },
        async deleteLicense(ctx, id: number) {
            try {
                await fetchDeleteLicense(id);
                return {
                    success: true,
                };
            } catch (e) {
                return {
                    success: false,
                    error: e,
                };
            }
        },
    } as ActionTree<any, any>,
    getters: {
        paginationData: (state) => {
            return {
                found: state.found,
                lastPage: state.lastPage,
                currentPage: state.currentPage,
                pageSize: state.pageSize,
                data: state.licensesArray,
            };
        },
        paginateQuery: (state) => {
            const offset = (state.currentPage - 1) * state.pageSize;
            const limit = state.currentPage * state.pageSize;
            return { offset, limit };
        },
    },
};
