import ForgotPassword from '@/views/auth/ForgotPassword.vue';
import ForgotPasswordConfirmation from '@/views/auth/ForgotPasswordConfirmation.vue';
import Login from '@/views/auth/Login.vue';
import NewPassword from '@/views/auth/NewPassword.vue';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
    },
    {
        path: '/forgot-password-confirmation',
        name: 'ForgotPasswordConfirmation',
        component: ForgotPasswordConfirmation,
        props: true,
    },
    {
        path: '/new-password',
        name: 'NewPassword',
        component: NewPassword,
        props: true,
    },
];

export default routes;
