import { Module, MutationTree } from 'vuex';

export const errorsModule: Module<any, any> = {
    namespaced: true,
    state: () => ({
        errorMessage: '',
    }),
    mutations: {
        SET_ERROR(state, errorMessage: string) {
            console.log(errorMessage);
            state.errorMessage = errorMessage;
        },
        RESET_ERROR_MESSAGE(state) {
            state.errorMessage = '';
        },
    } as MutationTree<any>,
};
