import { LoginGuard } from '@/guard/login';
import Auth from '@/views/Auth.vue';
import Entered from '@/views/Entered.vue';
import auth from '@/router/auth';
import lincenses from '@/router/lincenses';
import usages from '@/router/usages';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/auth',
        name: 'auth',
        component: Auth,
        meta: { requiresUnAuth: true },
        children: [...auth],
    },
    {
        path: '/',
        name: 'licenses',
        component: Entered,
        meta: {
            requiresAuth: true,
        },
        redirect: '/licenses',
        children: [...lincenses, ...usages],
    },
    {
        path: '/:catchAll(.*)',
        meta: {
            requiresAuth: true,
        },
        redirect: '/licenses',
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(LoginGuard);

export default router;
