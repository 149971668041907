
import AppErrorField from '@/components/AppErrorField.vue';
import { Auth } from 'aws-amplify';
import { defineComponent, ref, reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { Message, Lock } from '@element-plus/icons';
import { useStore } from 'vuex';

interface LoginStruct {
    email: string;
    password: string | undefined;
}

const Login = defineComponent({
    components: {
        AppErrorField,
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const loginData = reactive({
            loginForm: {
                email: '',
                password: '',
            } as LoginStruct,
            loginRef: ref(),
            errorMsg: ref<string | null>(null),
            rules: {
                email: [
                    {
                        required: true,
                        message: 'Please input email address',
                        trigger: ['blur'],
                    },
                    {
                        type: 'email',
                        message: 'Please input correct email address',
                        trigger: ['blur', 'change'],
                    },
                ],
                password: [
                    {
                        required: true,
                        message: 'Please input the password',
                        trigger: ['blur'],
                    },
                ],
            } as any,
        });

        const login = () => {
            loginData.loginRef.validate(async (valid: boolean) => {
                if (valid) {
                    try {
                        const user = await Auth.signIn(
                            loginData.loginForm.email,
                            loginData.loginForm.password,
                        );
                        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                            store.commit('auth/SET_LOGGED', user)
                            await router.push({
                                name: 'NewPassword',
                            });
                        } else {
                            store.commit('auth/SET_LOGGED', user);
                            await router.push('/');
                        }
                    } catch (err: any) {
                        loginData.errorMsg = err.message;
                    }
                } else {
                    return false;
                }
            });
        };

        return {
            ...toRefs(loginData),
            login,
            Message,
            Lock,
        };
    },
});
export default Login;
