
import { defineComponent, ref, computed } from 'vue';

const AppConfirmDeleteDialog = defineComponent({
    props: {
        id: {
            type: Number,
            default: null,
        },
        title: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
    },
    emits: ['submit-delete'],
    setup(props, context) {
        const isOpen = computed(() => {
            return props.id != null;
        });
        const handleSubmit = (agree: boolean) => {
            context.emit('submit-delete', agree);
        };
        const handleClose = () => {
            context.emit('submit-delete', false);
        };

        return {
            isOpen,
            handleSubmit,
            handleClose,
        };
    },
});
export default AppConfirmDeleteDialog;
