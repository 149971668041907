import router from '@/router';
import { CognitoUser } from '@/types/CognitoUser';
import { Auth } from 'aws-amplify';
import { ActionTree, Module, MutationTree } from 'vuex';

export const authModule: Module<any, any> = {
    namespaced: true,
    state: () => ({
        isLogged: false,
        redirectPath: '',
        user: { username: '' } as CognitoUser,
    }),
    mutations: {
        SET_LOGGED(state, cognitoUser: any) {
            state.isLogged = true;
            state.user = cognitoUser;
        },
        LOGOUT_SUCCESS(state) {
            state.isLogged = false;
            state.user = { username: '' };
        },
        SET_REDIRECT_PATH(state, redirectPath: string) {
            state.redirectPath = redirectPath;
            state.isLogged = false;
        },
    } as MutationTree<any>,
    actions: {
        async logout(ctx) {
            try {
                await Auth.signOut();
                ctx.commit('LOGOUT_SUCCESS');
                await router.push({ name: 'login' });
            } catch (err) {
                ctx.dispatch('errors/SET_ERROR', 'error logout: ' + err);
            }
        },
    } as ActionTree<any, any>,
};
