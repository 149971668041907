
import { useStore } from 'vuex';
import { UserFilled, Expand } from '@element-plus/icons';
import { defineComponent, computed, ref } from 'vue';
import { CognitoUser } from '@/types/CognitoUser';

export default defineComponent({
    components: { UserFilled, Expand },
    props: {},
    emits: ['toggle-menu'],
    setup(props, context) {
        const store = useStore();
        const user = computed((): CognitoUser => {
            return store.state.auth.user;
        });
        const logout = () => {
            store.dispatch('auth/logout');
        };

        const toggleMenu = () => {
            context.emit('toggle-menu');
        };

        return {
            user,
            toggleMenu,
            logout,
            UserFilled,
            Expand,
        };
    },
});
