import ApiUsages from '@/views/usages/ApiUsages.vue';
import FileUsges from '@/views/usages/FileUsages.vue';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/file-usages',
        name: 'FileUsages',
        component: FileUsges,
    },
    {
        path: '/api-usages',
        name: 'ApiUsages',
        component: ApiUsages,
    },
];

export default routes;
