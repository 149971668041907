import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-left red-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.error && _ctx.error.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.error), 1))
    : _createCommentVNode("", true)
}