import { API } from 'aws-amplify';
import { ResultType } from '@/types/PaginationData';

export const ApiName = 'LicensesApi';
export enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}
const myInit: any = {
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
        // OPTIONAL
    },
};
export const fetchApi = async (
    path: string,
    method: HttpMethod,
    body?: any,
) => {
    const init = {
        ...myInit,
        body,
    };
    let result;
    switch (method) {
        case HttpMethod.GET:
            result = await API.get(ApiName, path, init);
            break;
        case HttpMethod.POST:
            result = await API.post(ApiName, path, init);
            break;
        case HttpMethod.PUT:
            result = await API.put(ApiName, path, init);
            break;
        case HttpMethod.DELETE:
            result = await API.del(ApiName, path, init);
            break;
    }
    throwErrorIfFail(result);
    return result;
};

const throwErrorIfFail = (response: any) => {
    if (response.result == ResultType.failure) {
        throw Error(response.message);
    }
};
